<template>
  <div class="container">
    <a href="https://integris-mgt.com">
      <img src="https://integris-mgt.com/assets/img/logo/logo.svg" class="logo"
    /></a>
    <div class="card no-print-border">
      <div class="card-header border-0">
        <h1>2023 Pension Adjustment Calculation Tool</h1>
      </div>
      <div class="card-body">
        <form class="row" @submit.prevent>
          <div class="col-lg-6 d-print-none">
            <div class="mb-3">
              <label for="planRegistrationNo" class="form-label"
                >Plan Registration No. <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                id="planRegistrationNo"
                v-model="planRegistrationNo"
              />
            </div>
            <div class="mb-3">
              <label for="memberName" class="form-label"
                >Member Name <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                id="memberName"
                v-model="memberName"
              />
            </div>
            <div class="mb-3">
              <label for="provisionElected" class="form-label"
                >Provision Elected <span class="text-danger">*</span></label
              >
              <select
                class="form-select"
                id="provisionElected"
                v-model="provisionElected"
              >
                <option disabled value="">Choose...</option>
                <option value="DB">Defined Benefit Provision (DB)</option>
                <option value="DC">Defined Contribution Provision (DC)</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="t4Income" class="form-label"
                >2023 T4 Income <span class="text-danger">*</span></label
              >
              <input
                type="number"
                class="form-control"
                id="t4Income"
                v-model="t4Income"
                placeholder="153,425.85"
                min="0"
              />

              <div id="t4IncomeBlock" class="form-text">
                If the member received earnings from more than one participating
                employer during the year, you can report the Pension Adjustment
                on one of the participating employer's T4 slip. For this
                purpose, please enter the total T4 income (as reported in Box
                14) from all participating employers.
              </div>
            </div>
            <div class="mb-3" v-show="isDC">
              <label for="employerContributions" class="form-label"
                >Employer Contributions to the Member's DC Account
                <span class="text-danger">*</span></label
              >
              <input
                type="number"
                class="form-control"
                id="employerContributions"
                v-model="employerContributions"
                placeholder="0"
                min="0"
              />
              <div id="employerContributionsBlock" class="form-text">
                2023 contributions must not exceed the lesser of 18% of 2023 T4
                Income
                <strong>({{ formatCurrency(maxContributionByIncome) }})</strong>
                and <strong>{{ formatCurrency(limits.DC) }}</strong>
              </div>

              <div
                class="alert alert-danger"
                role="alert"
                v-show="showErrorEmployerContributions"
              >
                Exceeds maximum of
                {{ formatCurrency(maxContributionsDC) }}
              </div>
            </div>
            <div class="mb-3">
              <label for="vcAccountContributions" class="form-label"
                >Contributions to the Voluntary Contribution (VC) Account
                <span class="text-danger">*</span></label
              >
              <input
                type="number"
                class="form-control"
                id="vcAccountContributions"
                v-model="vcAccountContributions"
                placeholder="0"
                min="0"
              />
              <div id="vcAccountContributionsBlock" class="form-text">
                VC means Voluntary Contribution. Under the DB provision, the
                member may contribute up to $600 to the VC Account. Under the DC
                provision, contributions to the VC Account are not permitted.
              </div>

              <div
                class="alert alert-danger"
                role="alert"
                v-show="showErrorVcAccountContributionMax"
              >
                VC Contributions must not exceed
                {{ formatCurrency(vcAccountContributionMax) }}
              </div>

              <div
                class="alert alert-danger"
                role="alert"
                v-show="showErrorNoVCCAccountContributionAllowed"
              >
                No VC Contributions Allowed
              </div>
            </div>
          </div>
          <div class="col-lg-6 pt-4" id="printable">
            <h2>Information to report on {{ t4name }} T4 slip</h2>

            <div class="text-muted" v-show="!isFilledOut">
              Please fill out all required fields.
              <hr />
            </div>

            <div class="alert alert-danger" role="alert" v-show="isErrors">
              Please correct all errors first before
            </div>
            <div class="card card-body summary-info" v-show="isFilledOut && !isErrors">
              <div class="mb-3">
                <span>Box 50: RPP Registration Number: </span>
                <span
                  class="bg-dark text-white px-2"
                  v-text="planRegistrationNo"
                ></span>
              </div>
              <div class="mb-3" v-show="isDB">
                <span>Box 20: RPP (Voluntary) Contributions: </span>

                <span
                  class="bg-dark text-white px-2"
                  v-text="formatCurrency(rppVoluntaryContributions)"
                ></span>
              </div>
              <div class="mb-3">
                <span>Box 52: Pension Adjustment: </span>
                <span
                  class="bg-dark text-white px-2"
                  v-text="formatCurrency(pensionAdjustment,true)"
                ></span>
              </div>

              <hr class="d-print-none"/>
              <button
                class="btn btn-primary mt-3 d-print-none"
                @click="printDiv('printable')"
              >
                Print
              </button>
            </div>

            <div class="text-muted pt-2">
              *If the member has ceased membership during the year or joined the
              Plan part way through the year, please contact INTEGRIS
            </div>
          </div>
        </form>
      </div>
    </div>
    <footer class="py-3 my-4 text-muted">
      Have a questions? please contact
      <a href="mailto:actuarial@integri-mgt.com" class="btn-link"
        >actuarial@integri-mgt.com</a
      >
      <br />
      © INTEGRIS Pension Management Corp.
    </footer>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      limits: {
        DB: 3506.67,
        DC: 31560,
      },
      vcAccountContributionMax: 600,
      t4Income: 0,
      memberName: "",
      planRegistrationNo: "",
      provisionElected: "",
      vcAccountContributions: 0,
      employerContributions: 0,
    };
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.planRegistrationNo = urlParams.get("planRegistrationNo") || "";
    this.provisionElected = urlParams.get("provisionElected") || "";
  },

  computed: {
    isDB() {
      return this.provisionElected === "DB";
    },
    isDC() {
      return this.provisionElected === "DC";
    },
    t4name() {
      return this.memberName ? this.memberName + "'s" : "member's";
    },
    maxContributionByIncome() {
      return 0.18 * this.t4Income;
    },
    isErrors() {
      return (
        this.showErrorVcAccountContributionMax ||
        this.showErrorNoVCCAccountContributionAllowed ||
        this.showErrorEmployerContributions
      );
    },
    showErrorVcAccountContributionMax() {
      return (
        this.isDB && this.vcAccountContributions > this.vcAccountContributionMax
      );
    },
    showErrorNoVCCAccountContributionAllowed() {
      return this.isDC && this.vcAccountContributions > 0;
    },
    showErrorEmployerContributions() {
      return this.employerContributions > this.maxContributionsDC;
    },
    isFilledOut() {
      const hasValidProvision = this.isDB || this.isDC;
      const hasIncome = this.t4Income > 0;
      const hasPlanRegistrationNo = this.planRegistrationNo != "";
      return hasValidProvision && hasIncome && hasPlanRegistrationNo;
    },
    maxContributionsDC() {
      return Math.ceil(Math.min(this.limits.DC, this.maxContributionByIncome));
    },
    exceedsMaximumDC() {
      return this.employerContributions > this.maxContributionsDC;
    },

    rppVoluntaryContributions() {
      if (this.isDB) {
        return this.vcAccountContributions <= 600
          ? this.vcAccountContributions
          : "Error-VC Contributions Must Not Exceed $600";
      } else {
        return this.vcAccountContributions > 0
          ? "Error-No VC Contributions Allowed"
          : 0;
      }
    },
    pensionAdjustment() {
      if (this.isDB) {
        const baseAdjustment =
          Math.min(this.limits.DB, 0.02 * this.t4Income) * 9;
        return Math.round(baseAdjustment - 600) + Math.round(this.rppVoluntaryContributions)
      } else {
        return this.employerContributions;
      }
    },
  },
  methods: {
    formatCurrency(value, rounded) {
      if (rounded) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
                  minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(value);
      } else {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(value);
      }
    },
    printDiv(divName) {
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      console.log(printContents, originalContents);
      // document.body.innerHTML = printContents;
      window.print();
      // document.body.innerHTML = originalContents;
    },
  },
};
</script>

<style>
body {
  background: #eee !important;
}

h1,
h2 {
  font-size: 120% !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
}
.logo {
  width: 15%;
  margin: 1rem auto;
}
@media (max-width: 991.98px) {
  .logo {
    width: 35%;
    margin: 1rem auto;
  }
}
/* disable borders for print */
@media print {
  .no-print-border {
    border: none !important;
  }
  .summary-info .text-white{
    background-color: #fff !important;
    color: #000 !important;
    font-weight: bolder !important;
  }
}
</style>
